
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17.5px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input  {
  padding: 15px;
  outline: none;
  border-radius: 10px;
  border: none;
  width: 15em;
  font-size: 16.885px;
}

.button  {
  padding: 15px;
  width: 12.5em;
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.button:active  {
  padding: 15px;
  width: 12.5em;
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
  border: white 2px solid;
  font-size: 16px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.r {
  padding: 15px;
  width: 12.5em;
  background-color: white;
  color: black;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}

.r:active {
  padding: 15px;
  width: 12.5em;
  background-color: rgb(186, 186, 186);
  color: black;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}
.keyboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.buttono {
  width: 100px;
  height: 40px;
  border: 1px solid black;
  cursor: pointer;
}

.buttono:hover {
  background-color: lightgray;
}